<app-grid [data]="query" [selectable]="{ enabled: true, checkboxOnly: true }" [selection]="selection"
    [sortable]="true" [sort]="[{ field: 'number', dir: 'asc' }]">
    <kendo-grid-checkbox-column [width]="40" [showSelectAll]="true"></kendo-grid-checkbox-column>
    <kendo-grid-column title="{{'V/L Notification' | translate}}" field="id" [width]="120">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/short-vessel-notification/view', row.id]">
                <div [appPill]="{ status: row.statusId, module: 'vesselNotification' }">{{row.yearNumber}}</div>
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Name' | translate}}" field="vesselRevision.name">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="isRestricted(row.vessel.statusId)" class="badge box-shadow tight" style="font-size: 16px;"
                ngbTooltip="{{isRestricted(row.vessel.statusId, true)}}" placement="right">
                <fa-icon icon="exclamation" class="redIcon"></fa-icon>
            </div>
            <a *ngIf="!disableGridClickFilter" (click)="emitFilterData(row.vesselId, 'vesselId')"
                class="text-primary" target="_blank">
                {{row.vesselRevisionId | codelist:'VesselRevision' | async}}
            </a>
            <a *ngIf="disableGridClickFilter" [routerLink]="['/vessels/view', row.vesselId]" class="text-primary"
                target="_blank">
                {{row.vesselRevisionId | codelist:'VesselRevision' | async}}
            </a>
            <div class="badge box-shadow tight" ngbTooltip="{{row.vesselRevision.countryId | codelist:'Country' | async}}"
                placement="right" style="font-size: 16px;">
                <app-flag-icon [code]="row.vesselRevision.countryId"></app-flag-icon>
            </div>
            <span class="float-right">
                <span *ngIf="isRunningLate(row) <= 5">
                    <fa-icon [ngClass]="{'redIcon': isRunningLate(row) <= 0}"
                        ngbTooltip="{{row.notificationTypeId === 'A' ? 'ETA: ' + (row.portOfCallEta | date:'short') : 'ETD: ' + (row.portOfCallEtd | date:'short')}}"
                        placement="right" [tooltipClass]="isRunningLate(row) <= 0 ? 'error-tooltip' : ''"
                        icon="stopwatch" style="cursor: pointer; margin-right: 5px">
                    </fa-icon>
                </span>
                <fa-icon *ngIf="!!row.hasDefects" icon="wrench" style="cursor: pointer;"
                    (click)="openDefectsModal(row.id)">
                </fa-icon>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Notification Type' | translate}}" field="notificationType.name" [width]="125">
        <ng-template kendoGridCellTemplate let-row>
            {{row.notificationTypeId | codelist:'VesselNotificationType' | async}}
            <span class="float-right">
                <fa-icon *ngIf="canSeeRemarks(row)" style="cursor: pointer" icon="comments"
                    [ngClass]="{'redIcon': !row.portRemarksViewed && user?.isAgent()}" (click)="openRemarksModal(row)">
                </fa-icon>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Status' | translate}}" field="status.name" [width]="145">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="!disableGridClickFilter" [appPill]="{ status: row.statusId, module: 'vesselNotification' }"
                (click)="emitFilterData(row.statusId, 'statusId')">
                {{row.statusId | codelist:'VesselNotificationStatus' | async}}
            </div>
            <div *ngIf="disableGridClickFilter" [appPill]="{ status: row.statusId, module: 'vesselNotification', muted: true }">
                {{row.statusId | codelist:'VesselNotificationStatus' | async}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Vessel Visit" field="vesselVisitId" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <a class="text-primary" *ngIf="row.vesselVisitId" [routerLink]="['/vessel-visit/view', row.vesselVisitId]">
                {{row.vesselVisit.yearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Berth' | translate}}" field="berth.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.berthId | codelist:'Berth' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Port of Call ETA' | translate}}" field="portOfCallEta" [width]="140">
        <ng-template kendoGridCellTemplate let-row>
            {{row.portOfCallEta | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Port of Call ETD' | translate}}" field="portOfCallEtd" [width]="140">
        <ng-template kendoGridCellTemplate let-row>
            {{row.notificationTypeId === 'A' ? (!!row.vesselVisit?.departureVesselNotification ? (row.vesselVisit?.departureVesselNotification?.portOfCallEtd | date:'short') : '-') : (row.portOfCallEtd | date:'short')}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Agent' | translate}}" field="agent.name"></kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'TM' | translate}}" [width]="50">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="transportMalta" moduleType="VesselNotification"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
</app-grid>
