<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="queryName" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter [hasMetaFilter]="true" (search)="onAutoFilterChange()" (clearFilter)="clearFilter()">
    <div class="row" meta-filter>
        <div class="col">
            <app-control label='Conveyance Number' type="number" format="'#'" [min]="1"
                (ngModelChange)="onAutoFilterChange($event)"[(ngModel)]="filter.conveyanceNumber" [entity]="filter" property="conveyanceNumber"></app-control>
        </div>
        <div class="col">
            <app-control label='Type' type="codelist" codelist="ConveyanceType"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.conveyanceTypeId" [entity]="filter" property="conveyanceTypeId"></app-control>
        </div>
        <div class="col">
            <app-control label='Launch Operator' type="codelist" codelist="LaunchOperator"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.launchOperatorId" [entity]="filter" property="launchOperatorId"></app-control>
        </div>
        <div class="col">
            <app-control label='Status' type="codelist" codelist='ConveyanceStatus'
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.statusId" [entity]="filter" property="statusId"></app-control>
        </div>
    </div>
    <div class="row" all-filter>
        <div class="col-3">
            <app-control label='Conveyance Number' type="number" format="'#'" [min]="1"
                [(ngModel)]="filter.conveyanceNumber" [entity]="filter" property="conveyanceNumber"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Conveyance Type' type="codelist" codelist="ConveyanceType"
                [(ngModel)]="filter.conveyanceTypeId" [entity]="filter" property="conveyanceTypeId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Vessel Name' type="codelist" codelist="CurrentVesselRevision"
                [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Service Date From' type="datetime" [time]="true"
                [(ngModel)]="filter.dateFrom" [entity]="filter" property="dateFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Service Boat' type="codelist" codelist="ServiceBoat"
                [(ngModel)]="filter.serviceBoatId" [entity]="filter" property="serviceBoatId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Launch Operator' type="codelist" codelist="LaunchOperator"
                [(ngModel)]="filter.launchOperatorId" [entity]="filter" property="launchOperatorId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Agent' type="codelist" codelist="Agent"
                [(ngModel)]="filter.organizationId" [entity]="filter" property="organizationId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Conveyance Status' type="codelist" codelist='ConveyanceStatus'
                [(ngModel)]="filter.statusId" [entity]="filter" property="statusId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Previous Vessel Revisions' type="codelist" codelist="PreviousVesselRevision"
                [(ngModel)]="filter.previousVesselRevisionId" [entity]="filter" property="previousVesselRevisionId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Port Medical Approval' type="boolean"
                [(ngModel)]="filter.healthApproval" [entity]="filter" property="healthApproval"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Immigration Police Approval' type="boolean"
                [(ngModel)]="filter.immigrationApproval" [entity]="filter" property="immigrationApproval"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Customs Approval' type="boolean"
                [(ngModel)]="filter.customsApproval" [entity]="filter" property="customsApproval"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Transport Malta Approval' type="boolean"
                [(ngModel)]="filter.transportMaltaApproval" [entity]="filter" property="transportMaltaApproval"></app-control>
        </div>
    </div>
</app-filter>
<app-list-results-content [nrOfActiveFilters]="nrOfActiveFilters">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab title="{{'Conveyances' | translate}}" [selected]="true"></kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{'Archived Conveyances' | translate}}"></kendo-tabstrip-tab>
    </kendo-tabstrip>
    <app-conveyance-grid [query]="query" [selection]="selection"></app-conveyance-grid>
</app-list-results-content>
