<app-grid [data]="query" [selectable]="{ enabled: true, checkboxOnly: true }" [selection]="selection"
    [sortable]="true" [sort]="[{ field: 'id', dir: 'asc' }]">
    <kendo-grid-checkbox-column [width]="45" [showSelectAll]="true"></kendo-grid-checkbox-column>
    <kendo-grid-column title="{{'Conveyance Number' | translate}}" field="id" [width]="135">
        <ng-template style="align-items: center" kendoGridCellTemplate let-row>
            <a [routerLink]="['/conveyance/view', row.id]">
                <div [appPill]="{ status: row.statusId, module: 'vesselConveyance' }">{{row.yearNumber}}</div>
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Name' | translate}}" field="vesselRevision.name">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="isRestricted(row.vesselStatusId)" class="badge box-shadow tight" style="font-size: 16px;"
                ngbTooltip="{{isRestricted(row.vesselStatusId, true)}}" placement="right">
                <fa-icon icon="exclamation" class="redIcon"></fa-icon>
            </div>
            <a [routerLink]="['/vessels/view', row.vesselId]" class="text-primary" target="_blank">
                {{row.vesselRevisionId | codelist:'VesselRevision' | async}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Visit' | translate}}" [width]="65">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-visit/view', row.vesselVisitId]" class="text-primary" target="_blank">
                {{row?.vesselVisitYearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Port of call ETA' | translate}}" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            {{row.portOfCallEta | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Date and Time' | translate}}" field="edtOfService" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            {{row.edtOfService | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Type' | translate}}" field="type.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.conveyanceTypeId | codelist:'ConveyanceType' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Status' | translate}}" field="status.name" [width]="145">
        <ng-template kendoGridCellTemplate let-row>
            <div [appPill]="{ status: row.statusId, module: 'vesselConveyance' }">
                {{row.statusId | codelist:'ConveyanceStatus' | async}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Service Boat' | translate}}" field="serviceBoat.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.serviceBoatId | codelist:'ServiceBoat' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Launch Operator' | translate}}" field="launchOperator.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.launchOperatorId | codelist:'LaunchOperator' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Agent' | translate}}" field="organization.name">
    </kendo-grid-column>
    <kendo-grid-column title="{{'TM' | translate}}" [width]="40" [style]="{ 'text-align': 'center' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="transportMalta" moduleType="Conveyance"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'IM' | translate}}" [width]="40" [style]="{ 'text-align': 'center' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="immigration" moduleType="Conveyance"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'CU' | translate}}" [width]="40" [style]="{ 'text-align': 'center' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="customs" moduleType="Conveyance"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'HA' | translate}}" [width]="40" [style]="{ 'text-align': 'center' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="health" moduleType="Conveyance"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
</app-grid>