<app-loader [isBusy]="isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header title='Vessel Notifications' [breadcrumb]="breadcrumb"></app-content-header>
        <app-filter [hasMetaFilter]="true" (search)="onAutoFilterChange()" (clearFilter)="clearFilter()">
            <div class="row" meta-filter>
                <div class="col">
                    <app-control label='Status' type="codelist" codelist="VesselNotificationStatus" [multi]="true"
                        (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.statusIds" [entity]="filter" property="statusIds"></app-control>
                </div>
                <div class="col">
                    <app-control label='Notification Type' type="codelist" codelist="VesselNotificationType"
                        (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.notificationTypeId" [entity]="filter" property="notificationTypeId"></app-control>
                </div>
                <div class="col">
                    <app-control label='IMO Number' type="number" format="'#'" [maxlength]="7"
                        (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
                </div>
                <div class="col">
                    <app-control label='Vessel Name' type="codelist" codelist="CurrentVesselRevision"
                        (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
                </div>
                <div class="col-12">
                    <kendo-buttongroup class="port-filter" width="100%" selection="single">
                        <button *ngFor="let button of buttonFilters" kendoButton [toggleable]="true"
                            (click)="onButtonGroupSelect(button)" [ngClass]="{ 'selected': button === this.selectedButton }">
                            {{button.label}}
                        </button>
                    </kendo-buttongroup>
                </div>
            </div>
            <div all-filter>
                <div class="row">
                    <div class="col">
                        <app-control label='Status' type="codelist" codelist="VesselNotificationStatus"
                            [multi]="true" [(ngModel)]="filter.statusIds" [entity]="filter" property="statusIds"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Notification Type' type="codelist" codelist="VesselNotificationType"
                            [(ngModel)]="filter.notificationTypeId" [entity]="filter" property="notificationTypeId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Vessel Name' type="codelist" codelist="CurrentVesselRevision"
                            [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='IMO Number' type="number" format="'#'"
                            [maxlength]="7" [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='MMSI Number' type="number" format="'#'"
                            [maxlength]="9" [(ngModel)]="filter.mmsi" [entity]="filter" property="mmsi"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Primary Shipping Agency' type="codelist" codelist="Agent"
                            [(ngModel)]="filter.primaryAgentId" [entity]="filter" property="primaryAgentId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Purpose of Call' type="codelist" codelist="CallPurpose"
                            [(ngModel)]="filter.callPurposeId" [entity]="filter" property="callPurposeId"></app-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-control label='Previous Port of Call' type="codelist" codelist="Location"
                            [(ngModel)]="filter.lastPortId" [entity]="filter" property="lastPortId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Next Port of Call' type="codelist" codelist="Location"
                            [(ngModel)]="filter.nextPortId" [entity]="filter" property="nextPortId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Berth' type="codelist" codelist="Berth"
                            [(ngModel)]="filter.berthId" [entity]="filter" property="berthId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='ETA From' type="datetime" [time]="true"
                            [(ngModel)]="filter.etaFrom" [entity]="filter" property="etaFrom"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='ETA To' type="datetime" [time]="true"
                            [(ngModel)]="filter.etaTo" [entity]="filter" property="etaTo"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='ETD From' type="datetime" [time]="true"
                            [(ngModel)]="filter.etdFrom" [entity]="filter" property="etdFrom"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='ETD To' type="datetime" [time]="true"
                            [(ngModel)]="filter.etdTo" [entity]="filter" property="etdTo"></app-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-control label='Vessel Type' type="codelist" codelist="VesselType"
                            [(ngModel)]="filter.vesselTypeId" [entity]="filter" property="vesselTypeId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Given Name' [(ngModel)]="filter.givenName" [entity]="filter" property="givenName"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Family Name' [(ngModel)]="filter.familyName" [entity]="filter" property="familyName"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Document Id Number' [(ngModel)]="filter.idNumber" [entity]="filter" property="idNumber"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='V/L Notification' type="number" format="'#'" [min]="1"
                            [(ngModel)]="filter.number" [entity]="filter" property="number"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Vessel Flag' type="codelist" codelist="Country"
                            [(ngModel)]="filter.vesselCountryId" [entity]="filter" property="vesselCountryId"></app-control>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-4">
                                <app-control label='72h' type="boolean" [(ngModel)]="filter.preArrival" [entity]="filter" property="preArrival"></app-control>
                            </div>
                            <div class="col-8">
                                <app-control label='Waste Receipt' type="boolean" [(ngModel)]="filter.wasteReceipt" [entity]="filter" property="wasteReceipt"></app-control>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-control label='Previous Vessel Revisions' type="codelist" codelist="PreviousVesselRevision"
                            [(ngModel)]="filter.previousVesselRevisionId" [entity]="filter" property="previousVesselRevisionId"></app-control>
                    </div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col-12">
                        <kendo-buttongroup class="port-filter" width="100%" selection="single">
                            <button *ngFor="let button of buttonFilters" kendoButton [toggleable]="true"
                                (click)="onButtonGroupSelect(button)" [ngClass]="{ 'selected': button === this.selectedButton }">
                                {{button.label}}
                            </button>
                        </kendo-buttongroup>
                    </div>
                </div>
            </div>
        </app-filter>
        <app-list-results-content [nrOfActiveFilters]="nrOfActiveFilters">
            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                <kendo-tabstrip-tab [selected]="true" title="{{'Vessel Notifications' | translate}}"></kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="{{'Archived Vessel Notifications' | translate}}"></kendo-tabstrip-tab>
            </kendo-tabstrip>
            <app-vessel-notification-grid [query]="query" [selection]="selection" [user]="user"
                (dataChange)="search()" (rowParameterClicked)="searchBy($event)"></app-vessel-notification-grid>
        </app-list-results-content>
    </ng-template>
</app-loader>
