<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title='Vessel Shiftings' [breadcrumb]="breadcrumb"></app-content-header>
<app-filter [hasMetaFilter]="true" (search)="onAutoFilterChange()" (clearFilter)="clearFilter()">
    <div class="row" meta-filter>
        <div class="col">
            <app-control label='Vessel Name' type="codelist" codelist="CurrentVesselRevision"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
        </div>
        <div class="col">
            <app-control label='IMO Number' type="number" format="'#'" [maxlength]="7"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
        </div>
        <div class="col">
            <app-control label='Berth/Area From' type="codelist" codelist="Berth"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.berthShiftingFromId" [entity]="filter" property="berthShiftingFromId"></app-control>
        </div>
        <div class="col">
            <app-control label='Berth/Area To' type="codelist" codelist="Berth"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.berthShiftingToId" [entity]="filter" property="berthShiftingToId"></app-control>
        </div>
    </div>
    <div class="row" all-filter>
        <div class="col-3">
            <app-control label='Status' type="codelist" codelist="VesselShiftStatus"
                [multi]="true" [(ngModel)]="filter.statusIds" [entity]="filter" property="statusIds"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Vessel Name' type="codelist" codelist="CurrentVesselRevision" [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='IMO Number' type="number" format="'#'"
                [maxlength]="7" [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
        </div>
        <div class="col-3">
            <app-control label='MMSI Number' type="number" format="'#'"
                [maxlength]="9" [(ngModel)]="filter.mmsi" [entity]="filter" property="mmsi"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Agent' type="codelist" codelist="Organization"
                [(ngModel)]="filter.agentId" [entity]="filter" property="agentId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Berth/Area From' type="codelist" codelist="Berth"
                [(ngModel)]="filter.berthShiftingFromId" [entity]="filter" property="berthShiftingFromId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Berth/Area To' type="codelist" codelist="Berth"
                [(ngModel)]="filter.berthShiftingToId" [entity]="filter" property="berthShiftingToId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='V/S Notification' type="number" format="'#'" [min]="1"
                [(ngModel)]="filter.shiftNumber" [entity]="filter" property="shiftNumber"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Shifting From' type="datetime" [time]="true"
                [(ngModel)]="filter.shiftingFrom" [entity]="filter" property="shiftingFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Shifting To' type="datetime" [time]="true"
                [(ngModel)]="filter.shiftingTo" [entity]="filter" property="shiftingTo"></app-control>
        </div>
        <div class="col-3">
            <app-control label='ATS From' type="datetime" [time]="true"
                [(ngModel)]="filter.atsFrom" [entity]="filter" property="atsFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control label='ATS To' type="datetime" [time]="true"
                [(ngModel)]="filter.atsTo" [entity]="filter" property="atsTo"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Previous Vessel Revisions' type="codelist" codelist="PreviousVesselRevision"
                [(ngModel)]="filter.previousVesselRevisionId" [entity]="filter" property="previousVesselRevisionId"></app-control>
        </div>
    </div>
</app-filter>
<app-list-results-content [nrOfActiveFilters]="nrOfActiveFilters">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab [selected]="true" title="{{'Vessel Shifts' | translate}}"></kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{'Archived Vessel Shifts' | translate}}"></kendo-tabstrip-tab>
    </kendo-tabstrip>
    <app-vessel-shift-grid [query]="query" [selection]="selection"></app-vessel-shift-grid>
</app-list-results-content>
