<app-loader [isBusy]="!model">
    <ng-template>
        <div class="card" ng-model-options="{ allowInvalid: false, debounce: 200 }"
            style="border:0px solid !important">
            <div class="card-body">
                <div class="row mb-3 mr-1" *ngIf="!isCreate">
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto">{{model.notificationTypeId === 'A' ? 'Arrival' : 'Departure'}}</strong>
                            <div class="col-auto" [appPill]="{ status: model.statusId, module: 'vesselNotification', muted: true }">
                                {{model.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                            <div class="badge box-shadow tight" ngbTooltip="Vessel has DPG on board" tooltipClass="error-tooltip arrow-bottom">
                                <fa-icon *ngIf="model.hasHazmat" class="redIcon" icon="fire"></fa-icon>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong>
                                    <a *ngIf="!!model.vesselVisit && !currentlyOn('vessel-notification')" [routerLink]="['/vessel-notification/view', model.notificationTypeId === 'A' ? model.vesselVisit.arrivalVesselNotification.id : model.vesselVisit.departureVesselNotification.id]" target="_blank">{{model.yearNumber}} </a>
                                    <span *ngIf="currentlyOn('vessel-notification')">{{model.yearNumber}} </span>
                                </strong>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong class="bluify">Agent: </strong>{{model.agentId | codelist:'Organization' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'ETA: ' : 'ETD: '}} </strong>{{model.notificationTypeId === 'A' ? (model.portOfCallEta | date:'short') : (model.portOfCallEtd | date:'short')}}
                            </div>
                            <div class="col-auto" *ngIf="model.vesselVisitId">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'ATA: ' : 'ATD: '}} </strong>{{model.notificationTypeId === 'A' ? (model.vesselVisit.ata | date:'short') : (model.vesselVisit.atd | date:'short')}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-3">
                                <strong>TM</strong>
                                <app-rej-icon [item]="model" organizationName="transportMalta" moduleType="VesselNotification"></app-rej-icon>
                            </div>
                            <div class="col-3" *ngIf="model.portOfCallId !== 'MTWTW'">
                                <strong>IM</strong>
                                <app-rej-icon [item]="model" organizationName="immigration" moduleType="VesselNotification"></app-rej-icon>
                            </div>
                            <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                                <strong>CU</strong>
                                <app-rej-icon [item]="model" organizationName="customs" moduleType="VesselNotification"></app-rej-icon>
                            </div>
                            <div class="col-3" *ngIf="model.notificationTypeId === 'A'">
                                <strong>HA</strong>
                                <app-rej-icon [item]="model" organizationName="health" moduleType="VesselNotification"></app-rej-icon>
                            </div>
                            <div class="col-3"  *ngIf="model.notificationTypeId === 'A' && !model.isShort">
                                <strong>IS</strong>
                                <app-rej-icon [item]="model" organizationName="iSPS" moduleType="VesselNotification"></app-rej-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto">Notification Details</strong>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <strong>{{model.portOfCallId | codelist:'Location' | async}} / {{model.berthId | codelist:'Berth' | async}}</strong>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col">
                                <strong class="bluify">Side at Berth: </strong>{{model.berthTypeId | codelist:'BerthType' | async}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'Call purpose: ' : 'Next port: '}}</strong>{{model.notificationTypeId === 'A' ? (model.primaryCallPurposeId | codelist:'CallPurpose' | async) : (model.nextPortId | codelist:'Location' | async)}}
                            </div>
                        </div>
                        <div class="row mt-1" *ngIf="model.notificationTypeId === 'A'">
                            <div class="col">
                                <strong class="bluify">Call activity: </strong>{{model.callActivityId | codelist:'CallActivity' | async}}
                            </div>
                        </div>
                        <div class="row mt-1" *ngIf="model.notificationTypeId === 'A'">
                            <div class="col-auto">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'Arrival Draught Bow: ' : 'Departure Draught Bow: '}}</strong>{{model.notificationTypeId === 'A' ? model.arrivalDraughtBow : model.departureDraughtBow}} m
                            </div>
                            <div class="col-auto">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'Arrival Draught Stern: ' : 'Departure Draught Stern: '}}</strong>{{model.notificationTypeId === 'A' ? model.arrivalDraughtStern : model.departureDraughtStern}} m
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto">Visit</strong>
                            <div *ngIf="model.vesselVisitId" class="col-auto mr-2" [appPill]="{ status: model.vesselVisit.statusId, module: 'vesselVisit', muted: true }">
                                {{model.vesselVisit.statusId | codelist:'VesselVisitStatus' | async}}
                            </div>
                            <div *ngIf="isOpsConnected(model.vesselVisit)" class="badge box-shadow tight" style="font-size: 16px;" ngbTooltip="Vessel connected to OPS" placement="right">
                                <fa-icon icon="plug-circle-plus" class="orangeIcon"></fa-icon>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div *ngIf="model.vesselVisitId" class="col-6">
                                <strong class="bluify">Vessel Visit: </strong>
                                <strong> <a [routerLink]="['/vessel-visit/view', model.vesselVisitId]" target="_blank">{{model.vesselVisit.yearNumber}}</a></strong>
                            </div>
                            <div *ngIf="!model.vesselVisitId" class="col">
                                <strong>Vessel Visit: V/L does not have a Vessel visit</strong>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="model.notificationTypeId === 'A' && model.vesselVisitId">
                            <div class="col-auto" *ngIf="model.vesselVisit.departureVesselNotification">
                                <strong class="bluify">Departure: </strong>
                                <strong><a [routerLink]="['/vessel-notification/view', model.vesselVisit.departureVesselNotificationId]" target="_blank">{{model.vesselVisit.departureVesselNotification.yearNumber}}</a></strong>
                            </div>
                            <div class="col-auto" *ngIf="model.vesselVisit.departureVesselNotification" [appPill]="{ status: model.vesselVisit.departureVesselNotification.statusId, module: 'vesselNotification', muted: true }">
                                {{model.vesselVisit.departureVesselNotification.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="model.notificationTypeId === 'D' && model.vesselVisitId">
                            <div class="col-auto">
                                <strong class="bluify">Arrival: </strong>
                                <strong><a [routerLink]="['/vessel-notification/view', model.vesselVisit.arrivalVesselNotificationId]" target="_blank">{{model.vesselVisit.arrivalVesselNotification.yearNumber}}</a></strong>
                            </div>
                            <div class="col-auto" [appPill]="{ status: model.vesselVisit.arrivalVesselNotification.statusId, module: 'vesselNotification', muted: true }">
                                {{model.vesselVisit.arrivalVesselNotification.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="badge box-shadow tight" ngbTooltip="{{model.vesselRevision.countryId | codelist:'Country' | async}}">
                            <span>{{model.vesselRevision.countryId}}</span>
                            <app-flag-icon [code]="model.vesselRevision.countryId"></app-flag-icon>
                        </div>
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto">Vessel information</strong>
                            <strong class="col-auto"><a [href]="['https://www.marinetraffic.com/en/ais/details/ships/imo:' + model.vesselRevision.iMONumber]" target="_blank">({{model.vesselRevision.iMONumber}})</a></strong>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong><a [routerLink]="['/vessels/view', model.vesselId]" target="_blank">{{model.vesselRevisionId | codelist:'VesselRevision' | async}}</a></strong>
                                <fa-icon *ngIf="model.vesselRevision?.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                            </div>
                            <div *ngIf="isDetained() || isAlerted()" ngbTooltip="{{isDetained() ? 'Detained Vessel' : 'Alert Vessel'}}"
                                placement="right" style="font-size: 16px;">
                                <fa-icon icon="exclamation" class="redIcon"></fa-icon>
                            </div>
                            <div class="col-auto">
                                ({{model.vesselRevision.typeId | codelist:'VesselType' | async}})
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify">MMSI: </strong>{{model.vesselRevision.mmsi}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify">LOA: </strong>{{!!model.vesselRevision.length ? model.vesselRevision.length + ' m' : '/'}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify">Gross Tonnage: </strong>{{model.vesselRevision.grossTonnage}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify">Net Tonnage: </strong>{{!!model.vesselRevision.netTonnage ? model.vesselRevision.netTonnage : '/'}}
                            </div>
                        </div>
                    </div>
                </div>
                <ngb-accordion *ngIf="model.vessel" activeIds="agent-info">
                    <ngb-panel id="agent-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <div style="display: flex">
                                    <h5 translate>Agent in port at arrival</h5>
                                    <strong style="display: inline-block; margin-left: 4px; margin-top: 3px;">- Mandatory in order to
                                        submit the vessel notification.</strong>
                                </div>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-3">
                                    <app-control label='Primary Shipping Agency' type="codelist" codelist="Agent"
                                        [isDisabled]="user?.isAgent() || !editMode || user?.isSecurity() || user?.isHealth()"
                                        (ngModelChange)="onAgentChange($event)" [(ngModel)]="model.agentId" [entity]="model" property="agentId"></app-control>
                                </div>
                                <div class="col" *ngIf="model.agent">
                                    <app-control label='Primary shipping agency email'
                                        [isDisabled]="true" [(ngModel)]="model.agent.email" [entity]="model.agent" property="email"></app-control>
                                </div>
                                <div class="col" *ngIf="model.agent">
                                    <app-control label='Primary shipping agency phone' pattern="[\d]" prepend="+"
                                        [isDisabled]="true" [(ngModel)]="model.agent.phone" [entity]="model.agent" property="phone"></app-control>
                                </div>
                                <div class="col-2" *ngIf="model.agent">
                                    <app-control label='Primary shipping agency address'
                                        [isDisabled]="true" [(ngModel)]="model.agent.address" [entity]="model.agent" property="address"></app-control>
                                </div>
                                <div class="col-3" *ngIf="model.agent">
                                    <app-control label='Primary shipping agency on duty number' pattern="[\d]" prepend="+"
                                        [isDisabled]="!isEditable(0, false, true) || !editMode" [(ngModel)]="model.dutyNumber" [entity]="model" property="dutyNumber"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control #additionalAgentsControl label='Additional Agents' type="codelist" codelist="Agent"
                                        [filter]="excludePrimaryAgent.bind(this)" [multi]="true" [isDisabled]="!isEditable(0) || !editMode"
                                        (ngModelChange)="onAdditionalAgentsChange($event)" [(ngModel)]="additionalAgents" [entity]="" property="additionalAgents"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control #cargoAgentsControl label='Cargo Agents' type="codelist" codelist="CargoAgent"
                                        [multi]="true" [isDisabled]="!isEditable(0, true) || !editMode"
                                        (ngModelChange)="onCargoAgentsChange($event)" [(ngModel)]="cargoAgents" [entity]="" property="cargoAgents"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion *ngIf="viewMode" activeIds="approval-info">
                    <ngb-panel id="approval-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Approval/Rejection information</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <app-control label='Transport Malta approval date' type="datetime" [time]="true"
                                                [isDisabled]="true" [(ngModel)]="model.transportMaltaApprovalDate" [entity]="model" property="transportMaltaApprovalDate"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Transport Malta rejection date' type="datetime" [time]="true"
                                                [isDisabled]="true" [(ngModel)]="model.transportMaltaRejectionDate" [entity]="model" property="transportMaltaRejectionDate"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Transport Malta remarks' [isDisabled]="true"
                                                [(ngModel)]="model.transportMaltaRemarks" [entity]="model" property="transportMaltaRemarks"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Unconfirm remarks' [isDisabled]="true"
                                                [(ngModel)]="model.unconfirmRemarks" [entity]="model" property="unconfirmRemarks"></app-control>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <app-control label='Immigration approval date' type="datetime" [time]="true"
                                                [isDisabled]="true" [(ngModel)]="model.immigrationApprovalDate" [entity]="model" property="immigrationApprovalDate"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Immigration remarks' [isDisabled]="true"
                                                [(ngModel)]="model.immigrationRemarks" [entity]="model" property="immigrationRemarks"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Customs approval date' type="datetime" [time]="true"
                                                [isDisabled]="true" [(ngModel)]="model.customsApprovalDate" [entity]="model" property="customsApprovalDate"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Customs remarks' [isDisabled]="true"
                                                [(ngModel)]="model.customsRemarks" [entity]="model" property="customsRemarks"></app-control>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <app-control label='Health approval date' type="datetime" [time]="true"
                                                [isDisabled]="true" [(ngModel)]="model.healthApprovalDate" [entity]="model" property="healthApprovalDate"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='Health remarks' [isDisabled]="true"
                                                [(ngModel)]="model.healthRemarks" [entity]="model" property="healthRemarks"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='ISPS approval date' type="datetime" [time]="true"
                                                [isDisabled]="true" [(ngModel)]="model.iSPSApprovalDate" [entity]="model" property="iSPSApprovalDate"></app-control>
                                        </div>
                                        <div class="col-12">
                                            <app-control label='ISPS remarks' [isDisabled]="true"
                                                [(ngModel)]="model.iSPSSecurityRemark" [entity]="model" property="iSPSSecurityRemark"></app-control>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3" *ngIf="viewMode"></div>
                <div class="row mb-3" *ngIf="isCreate && !model.vessel">
                    <div class="container mt-5 mb-4">
                        <div class="row mb-3">
                            <div class="col">
                                <label class="vessel-select-text">Select a vessel to create a vessel notification:</label>
                                <kendo-autocomplete #autocomplete [data]="searchData" [filterable]="true"
                                    placeholder="Search criteria: IMO / MMSI / Vessel Name / Flag"
                                    (valueChange)="searchValueChange($event)"
                                    (filterChange)="searchFilterChange($event)" valueField="index"
                                    style="width: 100%; font-size: 16px;">
                                    <ng-template kendoAutoCompleteHeaderTemplate>
                                        <div class="row text-center"
                                            style="width: 100%; margin: 0; padding: 3px 1.5rem">
                                            <ng-container *ngFor="let item of vesselSelectSortFields">
                                                <div class="col h5" (click)="sortVesselSelect(item.field)"
                                                    style="cursor: pointer;"
                                                    [ngClass]="{'text-primary': item.sort, 'text-error': item.sort === false}">
                                                    <fa-icon *ngIf="item.sort !== null"
                                                        [icon]="item.sort ? 'arrow-down' : 'arrow-up'"></fa-icon>
                                                    {{item.columnName}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                    <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                        <div class="row text-center align-items-center" style="width: 100%; margin: 0">
                                            <div class="col">{{dataItem.vessel.iMONumber}}</div>
                                            <div class="col">{{dataItem.vessel.mmsi}}</div>
                                            <div class="col">{{dataItem.vessel.name}}
                                                <div *ngIf="dataItem.vessel.statusId === 'DT'" style="color: red;"> (Detained)</div>
                                                <div *ngIf="dataItem.vessel.statusId === 'BN'" style="color: orange;"> (Alert)</div>
                                            </div>
                                            <div class="col">{{dataItem.vessel.countryId}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-autocomplete>
                            </div>
                        </div>
                        <div class="row mb-3 text-center" *ngIf="selectedVessel">
                            <div class="col">
                                <div class="card mt-3" style="min-height: 150px;">
                                    <div class="card-body">
                                        <div class="h5">
                                            <a [routerLink]="['/vessels/view', selectedVessel.vessel.id]"
                                                class="text-primary">
                                                {{selectedVessel.vessel.name}}
                                            </a>
                                            <fa-icon *ngIf="selectedVessel.vessel.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                                            <!--
                                            <span *ngIf="selectedVessel.vessel.grayList">
                                                ( <fa-icon icon="flag" style="color: gray"></fa-icon> )
                                            </span>
                                            <span *ngIf="selectedVessel.vessel.greenList">
                                                ( <fa-icon icon="flag" style="color: green"></fa-icon> )
                                            </span>
                                            -->
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span>MMSI:</span> {{selectedVessel.vessel.mmsi}}
                                            </div>
                                            <div class="col">
                                                <span>Flag:</span>
                                                <app-flag-icon [code]="selectedVessel.vessel.countryId"
                                                    codelist="Country"></app-flag-icon>
                                            </div>
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span>Call Sign:</span> {{selectedVessel.vessel.callSign}}
                                            </div>
                                            <div class="col">
                                                <span>Year built:</span> {{selectedVessel.vessel.yearBuild}}
                                            </div>
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span>IMO:</span> {{selectedVessel.vessel.iMONumber }}
                                            </div>
                                            <div class="col">
                                                <span>Deadweight tonnage:</span> {{selectedVessel.vessel.deadWeight}}
                                            </div>
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span>Type:</span> {{selectedVessel.vessel.typeId | codelist:'VesselType' | async}}
                                            </div>
                                            <div class="col">
                                                <span>Gross tonnage:</span> {{selectedVessel.vessel.grossTonnage}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div *ngIf="isCreate && !model.vessel" class="row mb-3 text-center">
                            <div class="col">
                                <button *ngIf="selectedVessel" type="button" class="btn btn-success mr-1"
                                    (click)="createArrival()" translate>Create arrival</button>
                                <button *ngIf="!selectedVessel" type="button" class="btn btn-primary mr-1" [hidden]="!canImportIHSVessel()"
                                    (click)="importIHSVessel()" translate>Import IHS vessel</button>
                                <button *ngIf="!selectedVessel" type="button" class="btn btn-primary" [hidden]="!canImportEMSAVessel()"
                                    (click)="importEMSAVessel()" translate>Import EMSA vessel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="border:0; height: 4px; background: #FF934C;">
                <kendo-tabstrip #tabs (tabSelect)="onTabSelect($event)" *ngIf="model.vessel" class="icon-tabs mt-4 sticky-top"
                    [ngClass]="{'stickyClosed': actionBarStatus?.collapsed, 'stickyOpen': !actionBarStatus?.collapsed, 'stickyEmpty': actionBarStatus?.empty}">
                    <kendo-tabstrip-tab [selected]="isSelected(0)" [cssClass]="{'hidden-tab': !isVisible(0)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="map-signs"></fa-icon>
                                <div>{{'VOYAGE' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-voyage [model]="model" [user]="user" [editMode]="editMode"
                                [viewMode]="mode" [canEdit]="isEditable(0)"></app-vessel-notification-voyage>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(1)" [cssClass]="{'hidden-tab': !isVisible(1)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="database"></fa-icon>
                                <div>{{'BUNKERS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-bunker [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(1)"></app-vessel-notification-bunker>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(2)" [cssClass]="{'hidden-tab': !isVisible(2)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="biohazard"></fa-icon>
                                <div>{{'DPG' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-dangerous-goods [model]="model" [user]="user"
                                [editMode]="editMode" [canEdit]="isEditable(2)" [canOpenModal]="canOpenHazmatModal()"
                                (modalClose)="onStepModalClosed($event, 2)"></app-vessel-notification-dangerous-goods>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(3)" [cssClass]="{'hidden-tab': !isVisible(3)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <div [ngClass]="{ 'editable': (isEditable(3) && model.statusId === 'DP' && model.notificationTypeId === 'D')}">
                                    <fa-icon icon="cubes"></fa-icon>
                                    <div>{{'CARGO' | translate}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-cargo [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(3)" [canOpenModal]="canOpenCargoModal()"
                                (modalClose)="onStepModalClosed($event, 3)"></app-vessel-notification-cargo>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(4)" [cssClass]="{'hidden-tab': !isVisible(4)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="th-large"></fa-icon>
                                <div>{{"SHIP'S STORES"|translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-store-product [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(4)"></app-vessel-notification-store-product>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(5)" [cssClass]="{'hidden-tab': !isVisible(5)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="users"></fa-icon>
                                <div>{{'CREW' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-worker [model]="model" [editMode]="editMode"
                                [canEdit]="isEditable(5)"></app-vessel-notification-worker>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(6)" [cssClass]="{'hidden-tab': !isVisible(6)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <div [ngClass]="{ 'editable': (isEditable(6) && ['DP', 'AR', 'A'].includes(model.statusId)) }">
                                    <fa-icon icon="restroom"></fa-icon>
                                    <div>{{'PASSENGERS' | translate}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-passenger [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(6)"></app-vessel-notification-passenger>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(7)" [cssClass]="{'hidden-tab': !isVisible(7)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="shield-alt"></fa-icon>
                                <div>{{'ISPS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-security [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(7)"></app-vessel-notification-security>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(8)" [cssClass]="{'hidden-tab': !isVisible(8)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <div [ngClass]="{ 'editable': (isEditable(8) && ['DP', 'A'].includes(model.statusId) && model.notificationTypeId === 'D')}">
                                    <fa-icon icon="trash-alt"></fa-icon>
                                    <div>{{'WASTE' | translate}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-waste *ngIf="model.notificationTypeId === 'A'; else departureWaste" [model]="model" [user]="user" [editMode]="editMode" [canEdit]="isEditable(8)"></app-vessel-notification-waste>
                            <ng-template #departureWaste>
                                <app-vessel-notification-waste-departure [model]="model" [user]="user" [editMode]="editMode" [canEdit]="isEditable(8)"></app-vessel-notification-waste-departure>
                            </ng-template>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(9)" [cssClass]="{'hidden-tab': !isVisible(9)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="user-md"></fa-icon>
                                <div>{{'HEALTH' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-health [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(9)"></app-vessel-notification-health>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(10)" [cssClass]="{'hidden-tab': !isVisible(10)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="list-ul"></fa-icon>
                                <div>{{'ITINERARY' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-itinerary [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(10)"></app-vessel-notification-itinerary>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(11)" [cssClass]="{'hidden-tab': !isVisible(11)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="book"></fa-icon>
                                <div>{{'ATTACHMENTS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-attachment [attachments]="model.attachments" [user]="user"
                                [tabs]="!editMode" [editMode]="editMode" [canEdit]="isEditable(11)"></app-vessel-notification-attachment>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(12)" [cssClass]="{'hidden-tab': !isVisible(12)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <div [ngClass]="{ 'editable': (isEditable(12) && ['DP', 'AR', 'A'].includes(model.statusId)) }">
                                    <fa-icon icon="book"></fa-icon>
                                    <div>{{'ACCOMPANIED' | translate}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-accompanied [model]="model" [user]="user"
                                [tabs]="!editMode" [editMode]="editMode" [canEdit]="isEditable(12)"></app-vessel-notification-accompanied>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(13)" [cssClass]="{'hidden-tab': !isVisible(13)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="list"></fa-icon>
                                <div>{{'LOG' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-log [model]="model" [editMode]="editMode"
                                [canEdit]="isEditable(13)"></app-vessel-notification-log>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(14)" [cssClass]="{'hidden-tab': !isVisible(14)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="user-shield"></fa-icon>
                                <div>{{'PERMISSIONS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-permission [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(14)"></app-vessel-notification-permission>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(15)" [cssClass]="{'hidden-tab': !isVisible(15)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="print"></fa-icon>
                                <div>{{'PRINT' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-print [model]="model" [editMode]="editMode"
                                [isTabVisible]="isTabVisible"></app-vessel-notification-print>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(16)" [cssClass]="{'hidden-tab': !isVisible(16)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="list-ul"></fa-icon>
                                <div>{{'GENERAL REMARKS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-general-remarks [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(16)"></app-vessel-notification-general-remarks>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(17)" [cssClass]="{'hidden-tab': !isVisible(17)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="exclamation"></fa-icon>
                                <div>{{'DECLARATIONS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-details [model]="model" (tabSelected)="onDetailsTabSelected($event)"
                                [isTabVisible]="isTabVisible"></app-vessel-notification-details>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(18)" [cssClass]="{'hidden-tab': !isVisible(18)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <div [ngClass]="{ 'editable': (isEditable(18) && ['DP', 'AR', 'A'].includes(model.statusId))}">
                                    <fa-icon icon="list-alt"></fa-icon>
                                    <div>{{'MANIFESTS' | translate}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-manifest [model]="model" [editMode]="editMode" [user]="user" [canEdit]="isEditable(18)"></app-vessel-notification-manifest>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [selected]="isSelected(19)" [cssClass]="{'hidden-tab': !isVisible(19)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <div [ngClass]="{ 'editable': (isEditable(19) && ['DP', 'AR', 'A'].includes(model.statusId))}">
                                    <fa-icon icon="cubes"></fa-icon>
                                    <div>{{'CARGO DATA' | translate}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-cargo [model]="model" [editMode]="editMode" [isCargoData]="true"
                                [canEdit]="isEditable(19)" [canOpenModal]="canOpenCargoModal()"
                                (modalClose)="onStepModalClosed($event, 19)"></app-vessel-notification-cargo>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab cssClass="ml-auto float-right" *ngIf="canGoNext()">
                        <ng-template kendoTabTitle>
                            <div class="text-center" *ngIf="currentStep < finalStepIndex">
                                <fa-icon icon="arrow-circle-right"></fa-icon>
                                <div>{{'Next' | translate}}</div>
                            </div>
                            <div *ngIf="currentStep === finalStepIndex" class="text-center" (click)="confirmed = true">
                                <fa-icon icon="check-circle"></fa-icon>
                                <div>{{'Confirm' | translate}}</div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>
